<template>
  <div v-if="boxes.length">
    <TTableAdvance
      :items="boxes"
      :fields="fields"
      store="warehouse.boxes"
      reloadable
      noFilter
      resource=""
      noClickRow
      :options="{ border: false, striped: true }"
      class="my-3"
    >
      <template #box="{ item }">
        <td>
          <CRow style="margin-top: -13px; margin-left: -12px">
            <div v-if="!item.is_parent_box" class="d-flex flex-nowrap">
              <div class="bg-info p-1 d-flex" style="width: 110px">
                SFA:
                <TLink
                  v-if="item.sfa_id"
                  :content="item.sfa_id"
                  :to="lodash.getReferenceLink('sfa', item.sfa_id)"
                  :messageOptions="{ color: 'white', bold: true }"
                  class="ml-1"
                />
              </div>
              <div class="bg-primary p-1 d-flex" style="width: 200px">
                Tracking:
                <TMessage
                  v-if="item.sfa"
                  bold
                  noTranslate
                  :content="item.sfa.tracking_code"
                  class="ml-1"
                />
              </div>
            </div>
            <div v-else style="width: 300px" class="bg-white p-1">
              <TMessage content="Aggregate box" color="dark" bold />
            </div>
          </CRow>
          <div class="p-1">
            <div class="d-flex" style="width: 280px">
              <TLink
                :content="item.id"
                :to="lodash.getReferenceLink('box', item.id)"
                class="h4 m-0"
              />
              <TMessageDateTime
                class="ml-auto"
                :content="item.created_at"
                small
              />
            </div>
            <div
              v-if="item.tags && item.tags.length"
              class="d-flex flex-wrap my-1"
            >
              <SMessageBoxTag
                v-for="tag in item.tags"
                :key="tag.id"
                class="mr-1 mb-1"
                :tag="tag"
              />
            </div>
            <div class="d-flex">
              <TMessage content="Quantity" bold />:
              <CBadge color="info" class="my-auto ml-1">
                {{ item.duplicate }}
              </CBadge>
            </div>
            <div class="d-flex">
              <TMessage content="Quantity in stock" bold />:
              <CBadge color="info" class="my-auto ml-1">
                {{ item.quantity_in_inventory }}
              </CBadge>
            </div>
            <div class="d-flex">
              <TMessage content="Inventory number" bold />:
              <CBadge color="info" class="my-auto ml-1">
                {{ item.quantity_inventory }}
              </CBadge>
            </div>
          </div>
        </td>
      </template>
      <template #order="{ item }">
        <td>
          <div v-if="!item.is_parent_box">
            <CRow v-if="item.owners.length" class="m-0">
              <div v-for="order in item.owners" :key="order.id" class="w-100">
                <div class="card p-3 mb-1">
                  <TLink
                    v-if="order.order_id"
                    :content="order.order_id"
                    :to="lodash.getReferenceLink('order', order.order_id)"
                    class="h4 m-0"
                  />
                  <TMessageText
                    :value="order.user ? order.user.name : order.user_id"
                    class="h5 m-0"
                  />
                  <TMessage content="Distribution quantity">
                    <template #suffix
                      >:
                      <CBadge color="info">
                        {{ order.quantity }}
                      </CBadge>
                    </template>
                  </TMessage>
                </div>
              </div>
            </CRow>
            <div v-else>
              <TMessageNoData
                content="Not yet distributed"
                spacing="my-2"
                size="h5"
                alignment="left"
                iconSize="20"
              />
              <SButtonNextToDistribution
                class="ml-auto"
                v-if="item.sfa_id && item.quantity_inventory"
                :query="`search=${item.sfa.tracking_code}`"
              />
            </div>
          </div>
          <TMessageNoData
            v-else
            content="Empty"
            spacing="my-2"
            size="h5"
            alignment="left"
            iconSize="20"
          />
        </td>
      </template>
      <template #storage="{ item }">
        <td>
          <div v-if="!item.is_child_box">
            <CRow v-if="item.pivot_pallets.length" class="m-0">
              <div
                v-for="pallet in item.pivot_pallets"
                :key="pallet.id"
                class="w-100"
              >
                <div class="card p-3 mb-1">
                  <div class="d-flex">
                    <TLink
                      v-if="pallet.pallet_id"
                      :content="pallet.pallet_id"
                      :to="
                        lodash.getReferenceLink(
                          'assemble-to-stock.pallet',
                          pallet.pallet_id
                        )
                      "
                      class="h4 m-0"
                    />
                    <SMessagePalletLocation
                      v-if="pallet.pallet.location"
                      :location="pallet.pallet.location"
                      class="ml-auto my-auto"
                    />
                  </div>
                  <TMessage content="Quantity">
                    <template #suffix
                      >:
                      <CBadge color="info">
                        {{ pallet.current_quantity }}
                      </CBadge>
                    </template>
                  </TMessage>
                </div>
              </div>
            </CRow>
            <div v-else>
              <TMessageNoData
                content="Not ordered in stock yet"
                spacing="my-2"
                size="h5"
                alignment="left"
                iconSize="20"
              />
              <TButton
                v-if="item.quantity_available_in_pallet"
                class="ml-auto"
                content="Assemble to pallets"
                variant="outline"
                icon="cil-arrow-right"
                :to="lodash.getReferenceLink('assemble-to-stock.box', item.id)"
              />
            </div>
          </div>
          <div v-else>
            <TMessage content="Aggregate box reference" />
            <TLink
              :content="item.box_parent_id"
              :to="lodash.getReferenceLink('box', item.box_parent_id)"
              class="h4 m-0"
            />
          </div>
        </td>
      </template>
      <template #lading_bill="{ item }">
        <td>
          <CRow v-if="item.pivot_lading_bills.length" class="m-0">
            <div
              v-for="ldb in item.pivot_lading_bills"
              :key="ldb.id"
              class="card p-3 w-100 mb-1"
            >
              <CRow>
                <CCol col="6">
                  <TLink
                    :content="ldb.lading_bill_id.toString()"
                    :to="
                      lodash.getReferenceLink(
                        'lading_bill',
                        ldb.lading_bill_id.toString()
                      )
                    "
                    class="h4 m-0"
                  />
                  <TMessageText
                    :value="
                      ldb.customer
                        ? ldb.customer.name
                        : ldb.lading_bill
                        ? ldb.lading_bill.customer_id
                        : ''
                    "
                  />
                </CCol>
                <CCol col="6">
                  <TMessage
                    content="Quantity in lading bill"
                    bold
                    class="ml-auto"
                  >
                    <template #suffix
                      >:
                      <CBadge color="info">
                        {{ ldb.quantity }}
                      </CBadge>
                    </template>
                  </TMessage>
                </CCol>
              </CRow>
              <hr class="my-2" v-if="ldb.container" />
              <CRow style="w-100" v-if="ldb.container">
                <CCol col="6">
                  <TLink
                    v-if="ldb.lading_bill.container"
                    :content="`Invoice: ${ldb.lading_bill.container.name}`"
                    :to="
                      lodash.getReferenceLink(
                        'invoice',
                        ldb.lading_bill.container.id.toString()
                      )
                    "
                    class="h4 m-0"
                  />
                  <div>
                    <CBadge
                      color="primary"
                      v-if="ldb.lading_bill.container.from_area_id"
                    >
                      <TMessage
                        :content="ldb.lading_bill.container.from_area_id"
                        uppercase
                        noTranslate
                      />
                    </CBadge>
                    <CIcon class="mx-1" name="cil-arrow-circle-right" />
                    <CBadge color="info">
                      <TMessage
                        :content="ldb.lading_bill.container.to_area_id"
                        uppercase
                        noTranslate
                      />
                    </CBadge>
                  </div>
                  <TMessageDateTime
                    :content="ldb.lading_bill.container.shipping_date"
                    dateOnly
                  />
                </CCol>
                <CCol col="6" v-if="ldb.container">
                  <TMessage content="Quantity in cont" bold class="ml-auto">
                    <template #suffix
                      >:
                      <CBadge color="info">
                        {{ ldb.container.quantity_in_picker }}
                      </CBadge>
                    </template>
                  </TMessage>
                  <TMessage content="Quantity out cont" bold class="ml-auto">
                    <template #suffix
                      >:
                      <CBadge color="info">
                        {{ ldb.container.quantity_out_picker }}
                      </CBadge>
                    </template>
                  </TMessage>
                </CCol>
              </CRow>
              <div v-else class="d-flex">
                <TMessage content="Invoice" noTranslate bold />:
                <TMessageNotFound class="mt-auto ml-1" />
              </div>
            </div>
          </CRow>
          <div v-else>
            <TMessageNoData
              content="No lading bills"
              spacing="my-2"
              size="h5"
              alignment="left"
              iconSize="20"
            />
          </div>
          <hr />
          <CRow v-if="item.goods_deliveries.length" class="m-0">
            <div v-for="gd in item.goods_deliveries" :key="gd.id" class="w-100">
              <CCard class="p-3 mb-1">
                <TLink
                  :content="`${$t('Goods deliveries')}: ${gd.id.toString()}`"
                  :to="
                    lodash.getReferenceLink('goods_delivery', gd.id.toString())
                  "
                  class="h4 m-0"
                />
                <div>
                  <strong>{{ $t("To") }}</strong
                  >:
                  {{
                    gd.place_of_delivery.consignee +
                    " - " +
                    gd.place_of_delivery.full_address
                  }}
                </div>
                <div>
                  <strong>{{ $t("Shipping code") }}</strong
                  >:
                  {{
                    gd.pivot && gd.pivot.shipping_code
                      ? gd.pivot.shipping_code
                      : "..."
                  }}
                </div>
              </CCard>
            </div>
          </CRow>
        </td>
      </template>
    </TTableAdvance>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        { key: "box", label: "Box", _style: "width: 300px; min-width: 300px" },
        {
          key: "order",
          label: "Order",
          _style: "width: 230px; min-width: 230px",
        },
        {
          key: "storage",
          label: "Storage",
          _style: "width: 240px; min-width: 240px",
        },
        {
          key: "lading_bill",
          label: "Lading bill",
          _style: "width: 450px; min-width: 450px",
        },
      ],
    };
  },
  created() {
    this.$store.commit("warehouse.boxes.purge");
  },
  computed: {
    boxes() {
      return (
        this.$store.getters["warehouse.boxes.list"]?.map((x) => {
          return {
            ...x,
            pivot_lading_bills: x?.pivot_lading_bills.map((ldb) => {
              return {
                ...ldb,
                container: x.pivot_containers.find(
                  (cont) => cont.container_id == ldb.lading_bill.container_id
                ),
              };
            }),
            goods_deliveries: x?.goods_deliveries.map((gd) => {
              return {
                ...gd,
                pivot: x?.pivot_goods_deliveries.find(
                  (pgd) => pgd.goods_delivery_id == gd.id
                ),
              };
            }),
          };
        }) || []
      );
    },
  },
};
</script>