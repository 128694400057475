<template>
  <div>
    <CCard class="mt-3" @keypress.enter="search">
      <CCardHeader> <TMessage content="Search" bold /> </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="Tracking"
              placeholder="Tracking Id"
              :value.sync="filter.tracking"
              :inputOptions="{ lazy: false }"
              @input="filter.tracking = $event"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="SKU Id"
              placeholder="SKU Id"
              :value.sync="filter.id"
              :inputOptions="{ lazy: false }"
              @input="filter.id = $event"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="Shipping code"
              placeholder="Shipping code"
              :value.sync="filter['pivotGoodsDeliveries.shipping_code']"
              :inputOptions="{ lazy: false }"
              @input="filter['pivotGoodsDeliveries.shipping_code'] = $event"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectBoxTags label="Tags" :value.sync="filter['tags.id']" />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectSfa label="SFA" :value.sync="filter.sfa_id" prependAll />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="Goods"
              placeholder="Product Id"
              :value.sync="filter['items.product_id']"
              :inputOptions="{ lazy: false }"
              @input="filter['items.product_id'] = $event"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectCustomer
              :value.sync="filter['owners.user_id']"
              label="Customer"
              noCustomLabel
              noFetchStore
              prependAll
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectLadingBill
              label="Lading bill"
              :value.sync="filter['ladingBills.id']"
              :prepend="[{ id: '', name: $t('All') }]"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectInvoice
              prependAll
              :value.sync="filter['ladingBills.container_id']"
              label="Invoice"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectGoodsDelivery
              label="Goods delivery"
              :value.sync="filter['pivotGoodsDeliveries.goods_delivery_id']"
              :prepend="[{ id: '', name: $t('All') }]"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TMessage content="Dates" class="mb-2" bold />
            <TInputDateTimeRange
              :value.sync="dateRange"
              @update:value="setCreatedAtFilter"
            />
          </CCol>
        </CRow>
        <div class="float-right mt-3">
          <SButtonClearFilter
            :disabled="
              lodash.isEmpty(lodash.pickBy({ ...filter, ...filter_between }))
            "
            @click="clearFilter"
          />
          <SButtonSearchFilter
            class="ml-2"
            :disabled="loading"
            @click="search"
          />
        </div>
      </CCardBody>
    </CCard>
    <TMessageNoData v-if="!list.length && active && !loading" />
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      default: "boxes",
    },
    defaultFilter: {
      type: Object,
      default: () => {
        return {
          include:
            "sfa,owners,goodsDeliveries.placeOfDelivery,pivotContainers.container,pivotPallets.pallet.location,pivotLadingBills.ladingBill.container,tags,pivotGoodsDeliveries,owners.user,pivotLadingBills.customer",
        };
      },
    },
  },
  data() {
    return {
      filter: {},
      filter_between: {},
      active: false,
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    window.addEventListener("keypress", this.enter);
  },
  destroyed() {
    window.removeEventListener("keypress", this.enter);
  },
  computed: {
    list() {
      return this.$store.getters[`warehouse.${this.store}.list`];
    },
    loading() {
      return this.$store.getters[`warehouse.${this.store}.loading`];
    },
  },
  methods: {
    search() {
      if (this.loading) return;
      this.active = true;
      this.$router.push({}).catch(() => {});
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
        ...this.defaultFilter,
      });
      this.$store.dispatch(`warehouse.${this.store}.apply-query`, query);
    },
    enter(e) {
      if (e.key === "Enter") this.search();
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filter_between = {};
    },
  },
};
</script>
