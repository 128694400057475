<template>
  <div>
    <FilterDashboard />
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from "../components/Dashboard.vue";
import FilterDashboard from "../components/FilterDashboard.vue";
export default {
  components: { Dashboard, FilterDashboard },
};
</script>
